import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import styled from "styled-components";
import axios from "axios";
//components
import TopContainer from "../components/TopContainer";
//img
import bgImg from "../assets/img/qna/qna_bg1.jpg";

const QnaContainer = styled.div`
  .content-main {
    max-width: var(--max-width);
    margin: auto;
    padding: 60px 0;
    font-size: var(--font-medium);
    color: rgba(0, 0, 0, 0.5);
    input {
      height: 40px;
      background-color: var(--gray3);
      border: none;
      border-radius: 2px;
      text-indent: 10px;
    }
    .error-msg {
      visibility: hidden;
      font-size: var(--font-base);
      color: red;
      margin-top: 4px;
    }

    .user-info {
      h1 {
        color: black;
        font-size: var(--font-lg);
        font-weight: bolder;
        margin-bottom: 20px;
      }
      .content-wrap {
        display: flex;
        margin-bottom: 30px;
        .content {
          width: 50%;
          box-sizing: border-box;
          padding: 0 10px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            label {
              display: block;
              margin-bottom: 10px;
            }
            input {
              width: 100%;
            }
          }
        }
      }
    }
    .cargo-info {
      h1 {
        color: black;
        font-size: var(--font-lg);
        font-weight: bolder;
        margin-bottom: 20px;
      }
      .alert-msg {
        font-size: var(--font-base);
      }
      .input-wrap {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        margin: 20px 0;
        .input-content {
          width: 100%;
        }
        label {
          display: block;
          width: 300px;
        }
        input {
          &[type="radio"] {
            margin-left: 20px;
          }
          &[type="text"] {
            width: 100%;
            margin: 0;
          }
          &[type="date"] {
            width: 100%;
            height: 40px;
            margin: 0;
          }
          &[type="file"] {
            width: 100%;
            height: 40px;
            margin: 0;
            background-color: transparent;
          }
        }
        select.currency,
        select.sub-classification {
          width: 100%;
          height: 40px;
          text-indent: 10px;
        }
        .inner-wrap {
          width: 50%;
          box-sizing: border-box;
          padding: 0 10px;
          input {
            width: 100%;
          }
        }
        textarea {
          width: 100%;
          min-height: 200px;
          background-color: var(--gray3);
          border: none;
        }
        .multi-input-wrap {
          display: flex;
          flex-direction: row;
          width: 100%;
        }
      }
      .radio-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .radio-input-wrap {
          padding: 0 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .agree {
      p {
        box-sizing: border-box;
        background-color: var(--gray3);
        padding: 20px;
      }
      .agree-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          padding-left: 4px;
          font-size: var(--font-base);
        }
      }
    }
    .submitBtn {
      font-size: var(--font-medium);
      width: 100%;
      margin-top: 30px;
      padding: 8px 0;
      outline: none;
      border: none;
      background-color: var(--yellow);
      cursor: pointer;
    }
  }
  @media all and (max-width: 767px) {
    .content-main {
      width: 90%;
      margin: auto;
      .user-info {
        margin: auto;
        .content-wrap {
          flex-direction: column;
          .content {
            width: 100%;
          }
        }
      }
      .cargo-info {
        margin: auto;
        .input-wrap {
          margin: 30px 0;
          flex-direction: column;
          align-items: flex-start;
          span {
            font-size: var(--font-sm);
          }
          input {
            display: inline;
          }
          label {
            width: auto;
            margin: 10px 0;
          }
        }
        .multi-input-wrap {
          display: flex;
          flex-direction: row;
          .inner-wrap {
          }
        }

        .radio-wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          .radio-input-wrap {
            input {
              margin: 0;
            }
            padding: 0 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .additional-wrap {
          align-items: flex-start;
        }
      }
    }
  }
`;

function Qna() {
  const { t } = useTranslation();
  let today = new Date();
  let formatToday = today.toISOString().substr(0, 10);

  const formData = {
    company: "",
    tel: "",
    pic: "",
    phone: "",
    address: "",
    email: "",
    imex: "",
    cargo_type: "",
    incoterms: "",
    etd: "",
    pol: "",
    origin: "",
    pod: "",
    dest: "",
    cargo_name: "",
    hscode: "",
    price: "",
    currency: "",
    title: "",
    content: "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const thisForm = e.target;
    formData.company = thisForm.company.value;
    formData.tel = thisForm.tel.value;
    formData.pic = thisForm.pic.value;
    formData.phone = thisForm.phone.value;
    formData.address = thisForm.address.value;
    formData.email = thisForm.email.value;
    formData.imex = thisForm.imex.value;
    formData.cargo_type = thisForm.cargo_type.value;
    formData.incoterms = thisForm.incoterms.value;
    formData.etd = thisForm.etd.value;
    formData.pol = thisForm.pol.value;
    formData.origin = thisForm.origin.value;
    formData.pod = thisForm.pod.value;
    formData.dest = thisForm.dest.value;
    formData.cargo_name = thisForm.cargo_name.value;
    formData.hscode = thisForm.hscode.value;
    formData.price = thisForm.price.value;
    formData.currency = thisForm.currency.value;
    formData.title = thisForm.title.value;
    formData.content = thisForm.content.value;

    //formData_null체크(imex,cargo_type,incoterms,currency필드 검사제외)
    const { imex, cargo_type, incoterms, currency, ...otherFormData } =
      formData;

    let isNull = false;
    for (const key in otherFormData) {
      if (formData[key] === "") {
        const errorMsgElement = document.querySelector(
          `.error-msg[data-field="${key}"]`
        );
        errorMsgElement.style.visibility = "visible";
        isNull = true;
      } else {
        const errorMsgElement = document.querySelector(
          `.error-msg[data-field="${key}"]`
        );
        errorMsgElement.style.visibility = "hidden";
      }
    }

    if (isNull) {
      alert("필드를 입력해주세요.");
    } else {
      //빈값확인 후 formData 전송
      try {
        await axios({
          method: "post",
          url: "http://localhost:8000/sendmail",
          data: formData,
        });
        alert("문의가 등록되었습니다.");
      } catch (error) {
        alert("문의 등록 오류입니다.");
      }
    }
  };

  return (
    <QnaContainer>
      <TopContainer
        bgImg={bgImg}
        wrap={`YOU CAN REACH US`}
        desc={t("qna_top_desc")}
      ></TopContainer>
      <section className="content-main">
        <form onSubmit={handleSubmit}>
          <div className="user-info">
            <h1>{t("고객정보")}</h1>
            <div className="content-wrap">
              <div className="content">
                <div className="input-wrap">
                  <label htmlFor="company">{t("회사명")}</label>
                  <input type="text" name="company" id="company" />
                  <div className="error-msg" data-field="company">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="pic">{t("담당자")}</label>
                  <input type="text" name="pic" id="pic" />
                  <div className="error-msg" data-field="pic">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="address">{t("주소")}</label>
                  <input type="text" name="address" id="address" />
                  <div className="error-msg" data-field="address">
                    필수입력 필드입니다.
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="input-wrap">
                  <label htmlFor="tel">{t("회사연락처")}</label>
                  <input type="text" name="tel" id="tel" />
                  <div className="error-msg" data-field="tel">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="phone">{t("연락처")}</label>
                  <input type="text" name="phone" id="phone" />
                  <div className="error-msg" data-field="phone">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="email">{t("이메일")}</label>
                  <input type="text" name="email" id="email" />
                  <div className="error-msg" data-field="email">
                    필수입력 필드입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cargo-info">
            <h1>{t("화물정보")}</h1>
            <div className="input-wrap ">
              <label htmlFor="imex">{t("분류")}</label>
              <div className="radio-wrap">
                <div className="radio-input-wrap">
                  <input
                    type="radio"
                    name="imex"
                    value="export"
                    defaultChecked
                  />
                  <span>{t("수출")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" name="imex" value="import" />
                  <span>{t("수입")}</span>
                </div>
              </div>
            </div>
            <div className="input-wrap ">
              <label htmlFor="cargo_type">{t("선적조건")}</label>
              <div className="radio-wrap">
                <div className="radio-input-wrap">
                  <input
                    type="radio"
                    value="lcl"
                    name="cargo_type"
                    defaultChecked
                  />
                  <span>{t("form_LCL")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="fcl" name="cargo_type" />
                  <span>{t("form_FCL")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="air" name="cargo_type" />
                  <span>{t("form_AIR")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="bulk" name="cargo_type" />
                  <span>{t("form_BULK")}</span>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="incoterms">{t("무역조건")}</label>
              <div className="radio-wrap">
                <div className="radio-input-wrap">
                  <input
                    type="radio"
                    value="cfr"
                    name="incoterms"
                    defaultChecked
                  />
                  <span>CFR</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="cif" name="incoterms" />
                  <span>CIF</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="fob" name="incoterms" />
                  <span>FOB</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="exw" name="incoterms" />
                  <span>EXW</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="ddu" name="incoterms" />
                  <span>DDU(DAP)</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="etc" name="incoterms" />
                  <span>{t("기타")}</span>
                </div>
              </div>
            </div>
            <p className="alert-msg">{t("alert")}</p>
            <div className="input-wrap">
              <label htmlFor="etd">{t("예상선적일")}</label>
              <div className="input-content">
                <input
                  type="date"
                  id="etd"
                  name="etd"
                  defaultValue={formatToday}
                />
                <div className="error-msg" data-field="etd">
                  필수입력 필드입니다.
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label>{t("출발지")}</label>
              <div className="multi-input-wrap">
                <div className="inner-wrap">
                  <label htmlFor="pol">{t("포트")}</label>
                  <div className="input-content">
                    <input type="text" id="pol" />
                    <div className="error-msg" data-field="pol">
                      필수입력 필드입니다.
                    </div>
                  </div>
                </div>
                <div className="inner-wrap">
                  <label htmlFor="origin">{t("국가")}</label>
                  <input type="text" id="origin" />
                  <div className="error-msg" data-field="origin">
                    필수입력 필드입니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label>{t("도착지")}</label>
              <div className="multi-input-wrap">
                <div className="inner-wrap">
                  <label htmlFor="pod">{t("포트")}</label>
                  <input type="text" id="pod" />
                  <div className="error-msg" data-field="pod">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="inner-wrap">
                  <label htmlFor="dest">{t("국가")}</label>
                  <input type="text" id="dest" />
                  <div className="error-msg" data-field="dest">
                    필수입력 필드입니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="cargo_name">{t("제품명")}</label>
              <div className="input-content">
                <input type="text" id="cargo_name" />
                <div className="error-msg" data-field="cargo_name">
                  필수입력 필드입니다.
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="hscode">{t("세번부호")}</label>
              <div className="input-content">
                <input type="text" id="hscode" />
                <div className="error-msg" data-field="hscode">
                  필수입력 필드입니다.
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label>{t("물품가격")}</label>
              <div className="multi-input-wrap">
                <div className="inner-wrap">
                  <label htmlFor="price">{t("Price")}</label>
                  <input type="text" id="price" />
                  <div className="error-msg" data-field="price">
                    필수입력 필드입니다.
                  </div>
                </div>
                <div className="inner-wrap">
                  <label htmlFor="currency">{t("Currency")}</label>
                  <select className="currency" id="currency">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="YEN">YEN</option>
                    <option value="KRW">KRW</option>
                    <option value="AUD">AUD</option>
                    <option value="CNY">CNY</option>
                    <option value="ETC">ETC</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="additional">{t("추가의뢰사항")}</label>
              <div className="radio-wrap additional-wrap">
                <div className="radio-input-wrap ">
                  <input
                    type="radio"
                    value="exportCustomsClearance"
                    name="additional"
                    defaultChecked
                  />
                  <span>{t("수출통관")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input
                    type="radio"
                    value="domesticTransport"
                    name="additional"
                  />
                  <span>{t("국내운송")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="insurance" name="additional" />
                  <span>{t("보험부보")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="packing" name="additional" />
                  <span>{t("포장")}</span>
                </div>
                <div className="radio-input-wrap">
                  <input type="radio" value="storage" name="additional" />
                  <span>{t("보관")}</span>
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="title">{t("제목")}</label>
              <div className="input-content">
                <input type="text" id="title" />
                <div className="error-msg" data-field="title">
                  필수입력 필드입니다.
                </div>
              </div>
            </div>
            <div className="input-wrap">
              <label htmlFor="content">{t("내용")}</label>
              <div className="input-content">
                <textarea id="content"></textarea>
                <div className="error-msg" data-field="content">
                  필수입력 필드입니다.
                </div>
              </div>
            </div>
          </div>
          <div className="agree">
            <p>{t("개인정보활용동의내용")}</p>
            <div className="agree-checkbox">
              <input type="checkbox" />
              <span>{t("개인정보활용동의란")}</span>
            </div>
            {/* <div className="error-msg" aria-required> */}
            <div aria-required></div>
          </div>
          <button className="submitBtn" type="submit">
            {t("보내기")}
          </button>
        </form>
      </section>
    </QnaContainer>
  );
}

export default Qna;
