import FamilySection from "../components/family/FamilySection";
import FamilyMap from "../components/family/FamilyMap";
import TopContainer from "../components/TopContainer";
import bgImg from "../assets/img/info/family_main.jpg";
import { useTranslation } from "react-i18next";

function Family() {
  const { t } = useTranslation();
  return (
    <>
      <TopContainer
        bgImg={bgImg}
        wrap={`GLS GLOBAL NETWORK`}
        desc={t("info_top_desc")}
      ></TopContainer>
      <FamilySection></FamilySection>
      <FamilyMap></FamilyMap>
    </>
  );
}
export default Family;
