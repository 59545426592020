import styled from "styled-components";
//img,icons
import glsLogo from "../../assets/img/logo/glsconsol_h_white.png";

const FooterCHContainer = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family {
      color: white;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .family-item {
        width: 33%;
        box-sizing: border-box;
        padding: 30px 0;
        h1 {
          font-size: var(--font-base);
          font-weight: bolder;
          padding-bottom: 4px;
        }
        p {
          line-height: 1.6rem;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 20px 0;
    }
    .footer-bottom {
      .logo {
        width: 180px;
        margin: auto;
        img {
          width: 100%;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .content {
      .family {
        flex-direction: column;
        .family-item {
          width: 100%;
          padding: 20px 0;
        }
      }
    }
  }
`;

function FooterCH() {
  const family = [
    {
      id: "shenzhen",
      name: "SHENZHEN, CHINA",
      address:
        "ROOM 2416, CUNJIN BUILDING, NO.3005 SOUTH DONGMEN ROAD, LUOHU DISTRICT, SHENZHEN, GUANGDONG, CHINA",
      tel: "86-0755-8215-7011 #801",
      fax: "86-0755-8215-7230",
    },
    {
      id: "shanghai",
      name: "SHANGHAI, CHINA",
      address:
        "RM.2008-2009, PIAOYING BLDG, TIANBAO ROAD 578, HONGKOU DISTRICT, SHANGHAI, CHINA",
      tel: "86-21-5557-1001 #8002",
      fax: "86-21-5512-6911",
    },
    {
      id: "tianjin",
      name: "TIANJIN, CHINA",
      address:
        "RM B-1802, JINGCAI BLDG, DAGU SOUTH ROAD 459, HEXI DISTRICT, TIANJIN,CHINA",
      tel: "86-22-83869624",
      fax: "86-22-83868529",
    },
  ];

  return (
    <FooterCHContainer>
      <div className="content">
        <div className="family">
          {family.map((v, i) => {
            return (
              <div key={v.id} className="family-item">
                <h1>{v.name}</h1>
                <p>{v.address}</p>
                <p>Tel : {v.tel}</p>
                {v.fax ? <p>Fax : {v.fax}</p> : ""}
              </div>
            );
          })}
        </div>
        <div className="line"></div>
        <div className="footer-bottom">
          <div className="logo">
            <img src={glsLogo} alt="" />
          </div>
          {/* <p>© Copyright 2019 GLS Co.,Ltd. All Rights Reserved.</p> */}
        </div>
      </div>
    </FooterCHContainer>
  );
}

export default FooterCH;
