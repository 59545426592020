import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import homeSlider1 from "../../assets/img/home/home_swiper_img1.jpg";
import homeSlider2 from "../../assets/img/home/home_swiper_img2.jpg";
import glsLogo_white from "../../assets/img/logo/glsconsol_v_white.png";
import { useTranslation } from "react-i18next";

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  .swiper {
    height: 100%;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        width: 100%;
        height: 100%;
        position: relative;
        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .logo {
            width: 140px;
            margin: 14px auto;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
          h1 {
            font-size: var(--font-2xl);
            color: var(--yellow);
            font-weight: bolder;
            margin: 20px 0;
          }
          h2 {
            font-size: var(--font-xl);
            background-color: var(--yellow);
            font-weight: bolder;
            padding: 10px 0;
            margin: 20px 0;
          }
          p {
            color: white;
            font-size: var(--font-medium);
          }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .title {
            text-align: center;
            .logo {
              width: 120px;
              margin: 10px auto;
              img {
                width: 100%;
                object-fit: cover;
              }
            }
            h1 {
              font-size: var(--font-xl);
              color: var(--yellow);
              font-weight: bolder;
              margin: 20px 0;
            }
            h2 {
              width: 100vw;
              font-size: var(--font-lg);
              line-height: 3rem;
              background-color: var(--yellow);
              font-weight: bolder;
              margin: 20px 0;
            }
            p {
              width: 90%;
              margin: auto;
              color: white;
            }
          }
        }
      }
    }
  }
`;

export default function Slider() {
  const { t } = useTranslation();
  return (
    <SliderContainer>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <img src={homeSlider1} alt="" />
          <div className="title">
            <div className="logo">
              <img src={glsLogo_white} alt="GLS Logo" />
            </div>
            <h1>{t("글로벌 도약을 위한")}</h1>
            <h2>TOTAL LOGISTICS SERVICE</h2>
            <p>{t("글로벌도약_desc")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={homeSlider2} alt="" />
          <div className="title">
            <div className="logo">
              <img src={glsLogo_white} alt="GLS Logo" />
            </div>
            <h1>{t("고객을위한")}</h1>
            <h2>TOTAL LOGISTICS SERVICE</h2>
            <p>{t("고객을위한_desc")}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </SliderContainer>
  );
}
