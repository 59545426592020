import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function useGetLanguage(params) {
  const { i18n } = useTranslation();

  const [browserLanguage, setBrowserLanguage] = useState();
  const [i18Language, setI18Language] = useState();

  useEffect(() => {
    setBrowserLanguage(window.navigator.language.slice(0, 2));
    setI18Language(i18n.language.slice(0, 2));
  });

  return [browserLanguage, i18Language];
}

export default useGetLanguage;
