import { styled } from "styled-components";

const MainContainer = styled.div`
  .page-top-container {
    width: 100vw;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content-wrap {
      max-width: var(--max-width);
      margin: auto;
      text-align: center;
      .title-top {
        display: inline-block;
        padding: 1rem;
        border: 3px solid var(--yellow);
        font-size: var(--font-lg);
        color: var(--yellow);
      }
      .title-wrap {
        color: white;
        font-size: 6rem;
        margin: 3rem;
        & span:first-child {
          font-size: var(--font-xl);
          font-weight: bolder;
        }
      }
      p {
        font-size: 1.5rem;
        line-height: 1.6rem;
        color: white;
      }
    }
  }
  @media all and (max-width: 767px) {
    .page-top-container {
      .content-wrap {
        width: 80%;
        margin: auto;
        .title-top {
          font-size: var(--font-medium);
        }
        .title-wrap {
          font-size: var(--font-medium);
        }
        p {
          font-size: var(--font-base);
          line-height: 2rem;
        }
      }
    }
  }
`;

function TopContainer(props) {
  const bgImg = props.bgImg;
  const wrap = props.wrap;
  const desc = props.desc;
  return (
    <MainContainer>
      <section
        className="page-top-container"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="content-wrap">
          <div className="title-top">GLOBAL LOGISTICS SQUARE</div>
          <div className="title-wrap">{wrap}</div>
          <p>{desc}</p>
        </div>
      </section>
    </MainContainer>
  );
}
export default TopContainer;
