import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import CorpInfo from "../pages/CorpInfo";
import Info from "../pages/Info";
import Family from "../pages/Family";
import Service from "../pages/Service";
import Qna from "../pages/Qna";
import Consolbus from "../pages/Consolbus";
import Intro from "../components/consolbus/Intro";
import Advantage from "../components/consolbus/Advantage";
import App from "../App";
import NotFound from "../pages/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "corpinfo",
        element: <CorpInfo />,
        children: [
          {
            path: "",
            element: <Info />,
          },
          {
            path: "family",
            element: <Family />,
          },
        ],
      },
      {
        path: "service",
        element: <Service />,
      },
      {
        path: "qna",
        element: <Qna />,
      },
      {
        path: "consolbus",
        element: <Consolbus />,
        children: [
          {
            path: "",
            element: <Intro />,
          },
          {
            path: "advantage",
            element: <Advantage />,
          },
        ],
      },
    ],
    errorElement: <NotFound />,
  },
]);

export default router;
