import { useTranslation } from "react-i18next";
import styled from "styled-components";
//img,icons
import glsLogo from "../../assets/img/logo/glsconsol_h_white.png";
import { BsInstagram } from "react-icons/bs";
import { BiLogoFacebook } from "react-icons/bi";
import { BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const FooterKOContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .text {
    color: white;
    .logo {
      width: 180px;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .text-wrap {
      font-size: var(--font-base);
      p {
        font-size: var(--font-sm);
        line-height: 2rem;
      }
    }
  }
  .sns {
    .sns-item {
      display: inline-block;
      background-color: white;
      padding: 6px;
      margin-left: 16px;
      border-radius: 6px;
    }
  }

  @media all and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    .sns {
      padding-top: 30px;
    }
  }
`;

function FooterKO() {
  return (
    <FooterKOContainer>
      <div className="text">
        <div className="logo">
          <img src={glsLogo} alt="" />
        </div>
        <div className="text-wrap">
          <p>주소 : 인천광역시 서해대로 210번길 45 301호</p>
          <p>대표전화 : 02 – 3775 – 1180 </p>
          <p>팩스 : 02 – 3775 – 1181</p>
        </div>
      </div>
      <div className="sns">
        <Link
          to="https://www.instagram.com/glsconsol_official/"
          target="_blank"
        >
          <span className="sns-item">
            <BsInstagram />
          </span>
        </Link>
        <Link
          to="https://www.facebook.com/people/%EC%A7%80%EC%97%98%EC%97%90%EC%8A%A4-GLS/100063957586189/"
          target="_blank"
        >
          <span className="sns-item">
            <BiLogoFacebook />
          </span>
        </Link>
        <Link
          to="https://www.youtube.com/channel/UCO_-gBrTK4vIOhvIa3Ev9LA"
          target="_blank"
        >
          <span className="sns-item">
            <BsYoutube />
          </span>
        </Link>
      </div>
    </FooterKOContainer>
  );
}

export default FooterKO;
