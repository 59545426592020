import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
// img
import koreaFlag from "../assets/img/flag/korea.png";
import chinaFlag from "../assets/img/flag/china.png";
import hongkongFlag from "../assets/img/flag/hongkong.png";
import japanFlag from "../assets/img/flag/japan.png";
import vietnamFlag from "../assets/img/flag/vietnam.png";
import usaFlag from "../assets/img/flag/usa.png";
import logoBlue from "../assets/img/logo/glsconsol_v.png";
//icons
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";

const HeaderMobileContainer = styled.header`
  @media all and (max-width: 767px) {
    display: block;
  }
  @keyframes fadeInUp {
    0% {
      transform: translate(0px, 8px);
      opacity: 0;
    }
    100% {
      transform: translate(0px, 0);
      opacity: 1;
    }
  }
  display: none;
  height: 120px;
  box-sizing: border-box;
  border-bottom: 2px solid var(--yellow);
  background-color: white;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 999;

  .gnv-wrap {
    height: 100%;
    .topbar {
      width: 100%;
      height: 30%;
      background-color: var(--gray);
      .topbar-content-wrap {
        width: 90%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .flag-wrap {
          width: 300px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .flag {
            width: 30px;
            height: 100%;
            padding: 0;
            border: none;
            outline: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .navbar {
      height: 70%;
      text-decoration: none;
      .active {
        color: var(--yellow);
      }
      .navbar-content-wrap {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .logo {
          width: 140px;
          height: 80%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .menu-wrap {
          svg {
            color: #666666;
            font-size: 32px;
          }
        }
      }
      .menu-item-wrap {
        box-sizing: border-box;
        width: 90%;
        margin: auto;
        background-color: var(--gray3);
        animation: fadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        li {
          width:100%
          border-bottom: 1px solid var(--hover-gray);
          padding: 14px;
          line-height: 1.4rem;
          a {
            display: block;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            font-size: var(--font-base);
            text-decoration: none;
            font-weight: normal;
            color: var(--font-gray);
          }
        }
        .sub-menu-wrap {
          display: flex;
          justify-content: space-between;
          button {
            box-sizing: border-box;
            font-size: var(--font-base);
            color: var(--font-gray);
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: none;
            outline: none;
            background-color: transparent;
          }
        }
        .sub-menu-item {
          li {
            border: none;
            a {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

function HeaderMobile() {
  const { t, i18n } = useTranslation();
  const [browserLanguage, setBrowserLanguage] = useState();
  const [i18Language, setI18Language] = useState();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

  useEffect(() => {
    setBrowserLanguage(window.navigator.language.slice(0, 2));
  }, [browserLanguage]);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.dataset.language);
    setI18Language(i18n.language);
  };

  return (
    <HeaderMobileContainer className="gnv-mobile">
      <div className="gnv-wrap">
        <section className="topbar">
          <div className="topbar-content-wrap">
            <div className="flag-wrap">
              <button className="flag" onClick={handleLanguageChange}>
                <img src={koreaFlag} alt="korea" data-language="ko" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={vietnamFlag} alt="vietnam" data-language="en" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={japanFlag} alt="japan" data-language="ja" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={hongkongFlag} alt="hongkong" data-language="en" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={chinaFlag} alt="china" data-language="zh" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={usaFlag} alt="usa" data-language="en" />
              </button>
            </div>
          </div>
        </section>
        <section className="navbar">
          <nav className="navbar-content-wrap">
            <div className="logo">
              <Link
                to="/"
                onClick={(e) => {
                  setIsOpenMenu(false);
                }}
              >
                <img src={logoBlue} alt="GLS logoBlue" />
              </Link>
            </div>
            <div
              className="menu-wrap"
              onClick={() => {
                setIsOpenMenu((prev) => !prev);
                setIsOpenSubMenu(false);
              }}
            >
              <RxHamburgerMenu />
            </div>
          </nav>
          {isOpenMenu ? (
            <ul className="menu-item-wrap">
              <li className="navbar-menu-item sub-menu-wrap">
                <button
                  type="button"
                  className="corpinfo-btn"
                  onClick={() => {
                    setIsOpenSubMenu((prev) => !prev);
                  }}
                >
                  {t("회사소개")}
                  <span>
                    <IoIosArrowDown />
                  </span>
                </button>
              </li>
              {isOpenSubMenu ? (
                <li className="sub-menu-item">
                  <ul>
                    <li className="navbar-menu-item">
                      <NavLink
                        to="corpinfo"
                        onClick={(e) => {
                          setIsOpenMenu(false);
                        }}
                      >
                        {t("개요")}
                      </NavLink>
                    </li>
                    <li className="navbar-menu-item">
                      <NavLink
                        to="corpinfo/family"
                        onClick={(e) => {
                          setIsOpenMenu(false);
                        }}
                      >
                        {t("GLS Family")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
              <li className="navbar-menu-item">
                <NavLink
                  to="service"
                  onClick={(e) => {
                    setIsOpenMenu(false);
                  }}
                >
                  {t("서비스")}
                </NavLink>
              </li>
              <li className="navbar-menu-item">
                <NavLink
                  to="*"
                  onClick={(e) => {
                    e.preventDefault();
                    alert("회원전용페이지입니다.");
                  }}
                >
                  {t("E-TRACKING")}
                </NavLink>
              </li>
              <li className="navbar-menu-item">
                <NavLink
                  to="qna"
                  onClick={(e) => {
                    setIsOpenMenu(false);
                  }}
                >
                  {t("문의하기")}
                </NavLink>
              </li>
              {i18Language === "ko" || browserLanguage === "ko" ? (
                <li className="navbar-menu-item">
                  <NavLink
                    to="consolbus"
                    onClick={(e) => {
                      setIsOpenMenu(false);
                    }}
                  >
                    {t("CONSOLBUS")}
                  </NavLink>
                </li>
              ) : (
                ""
              )}
            </ul>
          ) : (
            ""
          )}
        </section>
      </div>
    </HeaderMobileContainer>
  );
}

export default HeaderMobile;
