import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .msg-wrap {
    max-width: var(--max-width);
    width: 50%;
    height: 30%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
    font-weight: bolder;
    font-size: var(--font-2xl);
    text-align: center;
    background-color: var(--yellow);
  }
  .go-home {
    margin: 20px 0;
    a {
      text-decoration: underline;
    }
  }
`;

function NotFound() {
  return (
    <NotFoundContainer>
      <div className="msg-wrap">
        <span>404</span>
        <span>PAGE NOT FOUND</span>
      </div>
      <div className="go-home">
        <Link to="/">HOME으로 돌아가기</Link>
      </div>
    </NotFoundContainer>
  );
}
export default NotFound;
