import styled from "styled-components";
import { BiArrowToTop } from "react-icons/bi";
import { useEffect, useState } from "react";

const ScrollTopBtnContainer = styled.button`
  position: fixed;
  bottom: 20px;
  right: 16px;
  z-index: 2;
  border-radius: 50%;
  background-color: var(--yellow);
  padding: 6px 10px;
  font-size: 2rem;
  color: white;
  font-weight: 900;
  border: none;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

function ScrollTopBtn() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleShowTopBtn = () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener("scroll", handleShowTopBtn);
    return () => {
      window.removeEventListener("scroll", handleShowTopBtn);
    };
  }, []);

  return (
    showTopBtn && (
      <ScrollTopBtnContainer type="button" onClick={scrollToTop}>
        <BiArrowToTop />
      </ScrollTopBtnContainer>
    )
  );
}
export default ScrollTopBtn;
