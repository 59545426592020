import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ProcessLineContainer = styled.article`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .process-item {
    box-sizing: border-box;
    width: 25%;
    height: auto;
    /* position: relative; */
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-wrap {
      width: 60%;
      position: relative;
      img {
        width: 100%;
      }
      h1 {
        position: absolute;
        width: 100%;
        top: 32%;
        left: 40%;
        transform: translate(-50%, 0);
        font-size: var(--font-medium);
        font-weight: bolder;
        word-break: normal;
      }
    }
    p {
      word-break: normal;
      font-size: var(--font-base);
      line-height: 1.8rem;
    }
  }
  @media all and (max-width: 767px) {
    .process-item {
      .img-wrap {
        h1 {
          box-sizing: border-box;
          width: 100vw;
          padding: 0 20px;
          font-size: var(--font-medium);
        }
      }
    }
  }
`;

function ProcessLine() {
  const { t } = useTranslation();
  const processLineContents = [
    {
      title: t("GLS본사"),
      desc: t("GLS본사DESC"),
    },
    {
      title: t("창고사업부"),
      desc: t("창고사업부DESC"),
    },
    {
      title: t("통관사업부"),
      desc: t("통관사업부DESC"),
    },
    {
      title: t("운송사업부"),
      desc: t("운송사업부DESC"),
    },
  ];

  return (
    <ProcessLineContainer className="process-line-container">
      {processLineContents.map((item, index) => {
        index++;
        return (
          <div className="process-item" key={index}>
            <div className="img-wrap">
              <img
                src={require(`../../assets/img/home/number${index}.png`)}
                alt={"number" + index}
              />
              <h1>{item.title}</h1>
            </div>
            <p>{item.desc}</p>
          </div>
        );
      })}
    </ProcessLineContainer>
  );
}

export default ProcessLine;
