import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
//components
import ServiceArticle from "../components/common/ServiceArticle";
import TopContainer from "../components/TopContainer";
//img
import bgImg from "../assets/img/service/service_bg.jpg";
import serviceArticleBg1 from "../assets/img/service/service_article_bg1.jpg";
import serviceArticleBg2 from "../assets/img/service/service_article_bg2.jpg";
import serviceArticleBg3 from "../assets/img/service/service_article_bg3.jpg";
import serviceArticleBg4 from "../assets/img/service/service_article_bg4.jpg";
import glsLogo from "../assets/img/logo/glsconsol_h.png";
//icons
import { MdLocalShipping } from "react-icons/md";
import { FaShip } from "react-icons/fa";
import { BsAirplaneFill } from "react-icons/bs";

const ServiceContainer = styled.div`
  .title {
    max-width: var(--max-width);
    margin: auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .logo-wrap {
      width: 300px;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    p {
      margin: 4px 0;
      line-height: 1.8rem;
    }
  }

  .tab-btn-wrap {
    display: flex;
    justify-content: center;
    button {
      background-color: var(--dark-gray);
      border: none;
      outline: none;
      margin: 0 2px;
      padding: 16px 10px;
      font-size: var(--font-medium);
      &:hover {
        background-color: #ccc;
      }
    }
  }
  .tab-container {
    width: 100vw;
    background-color: var(--gray3);
    .content-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 60px 0;

      .content-title {
        font-size: var(--font-lg);
      }
      .subtitle {
        font-size: var(--font-lg);
        color: var(--yellow);
        font-weight: bolder;
        margin: 10px 0;
      }
      .content-desc {
        text-align: center;
        margin: 20px 0;
        p {
          line-height: 1.8rem;
          margin: 4px 0;
        }
      }

      .service-article-wrap {
        width: 90%;
        box-sizing: border-box;
        padding: 60px 20px;
        color: white;
        transition: all ease-in-out 0.2s;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  @media all and (max-width: 767px) {
    .tab-btn-wrap {
      button {
        color: #666666;
        font-size: var(--font-xs);
      }
    }
  }
`;

function Service() {
  const { t } = useTranslation();
  let [title, setTitle] = useState(t("해상운송"));
  let [service, setService] = useState("sea");
  let servieBgImg;
  switch (service) {
    case "sea":
      servieBgImg = serviceArticleBg1;
      break;
    case "air":
      servieBgImg = serviceArticleBg2;
      break;
    case "cargo":
      servieBgImg = serviceArticleBg3;
      break;
    case "consol":
      servieBgImg = serviceArticleBg4;
      break;
    default:
      break;
  }

  return (
    <ServiceContainer>
      <TopContainer
        bgImg={bgImg}
        wrap={`OUR SERVICES`}
        desc={t("service_top_desc")}
      ></TopContainer>
      <div className="title">
        <div className="logo-wrap">
          <img src={glsLogo} alt="" />
        </div>
        <div>
          <p>{t("service_article_desc1")}</p>
          <p>{t("service_article_desc2")}</p>
        </div>
      </div>
      <div className="tab-btn-wrap">
        <button
          type="button"
          style={{
            backgroundColor: service === "sea" ? "var(--gray3)" : "",
          }}
          onClick={(e) => {
            setService("sea");
            setTitle(e.target.innerText);
          }}
        >
          {t("해상운송")}
        </button>
        <button
          type="button"
          style={{
            backgroundColor: service === "air" ? "var(--gray3)" : "",
          }}
          onClick={(e) => {
            setService("air");
            setTitle(e.target.innerText);
          }}
        >
          {t("항공운송")}
        </button>
        <button
          type="button"
          style={{
            backgroundColor: service === "cargo" ? "var(--gray3)" : "",
          }}
          onClick={(e) => {
            setService("cargo");
            setTitle(e.target.innerText);
          }}
        >
          {t("벌크카고")}
        </button>
        <button
          type="button"
          style={{
            backgroundColor: service === "consol" ? "var(--gray3)" : "",
          }}
          onClick={(e) => {
            setService("consol");
            setTitle(e.target.innerText);
          }}
        >
          {t("콘솔업무")}
        </button>
      </div>
      <div className="tab-container">
        <div className="content-wrap">
          <h1 className="content-title ">{title}</h1>
          <h1 className="subtitle">{service}</h1>
          <div className="content-desc">
            <p>{t("service_article_desc1")}</p>
            <p>{t("service_article_desc2")}</p>
          </div>
          <div
            className="service-article-wrap"
            style={{ backgroundImage: `url(${servieBgImg})` }}
          >
            <ServiceArticle params={service}></ServiceArticle>
          </div>
        </div>
      </div>
    </ServiceContainer>
  );
}

export default Service;
