import styled from "styled-components";
import useGetLanguage from "../hooks/useGetLanguage";
//components
import FooterEN from "./Footer/FooterEN";
import FooterCH from "./Footer/FooterCH";
import FooterJP from "./Footer/FooterJP";
import FooterKO from "./Footer/FooterKO";

const FooterContainer = styled.div`
  width: 100vw;
  box-sizing: border-box;
  background-color: var(--dark-blue);
  .content-wrap {
    max-width: var(--max-width);
    margin: auto;
    padding: 40px 20px;
  }
`;

function Footer() {
  const [browserLanguage, i18Language] = useGetLanguage();
  // console.log(browserLanguage, i18Language);

  return (
    <FooterContainer>
      {i18Language === "en" ? (
        <div className="content-wrap">
          <FooterEN />
        </div>
      ) : i18Language === "zh" ? (
        <div className="content-wrap">
          <FooterCH />
        </div>
      ) : i18Language === "ja" ? (
        <div className="content-wrap">
          <FooterJP />
        </div>
      ) : i18Language === "ko" ? (
        <div className="content-wrap">
          <FooterKO />
        </div>
      ) : (
        ""
      )}
    </FooterContainer>
  );
}

export default Footer;
