import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
//다국어처리모듈
import i18n from "./lang/i18n";
import { I18nextProvider } from "react-i18next";
//components
import Meta from "./Meta";
import GlobalStyles from "./GlobalStyles";
import ResponsiveStyles from "./ResponsiveStyles";
import Animations from "./Animations";
import router from "./router/Router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Meta />
    <GlobalStyles />
    <ResponsiveStyles />
    <Animations />
    <I18nextProvider i18n={i18n(window.navigator.language)}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);
