/**
 * @description intersectionObserver활용 스크롤위치감지 fadeInUp animation구현
 */
const fadeAnimation = () => {
  function fadeInUP(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add("fade-in-up");
      }
    });
  }

  let observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.75,
  };

  let observer = new IntersectionObserver(fadeInUP, observerOptions);

  const fadeInElements = document.querySelectorAll(".fade");
  fadeInElements.forEach((el) => {
    observer.observe(el);
  });
};
export default fadeAnimation;
