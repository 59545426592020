import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CorpInfo() {
  const { t } = useTranslation();

  return (
    <div>
      <Outlet></Outlet>
    </div>
  );
}

export default CorpInfo;
