import { useTranslation } from "react-i18next";
import styled from "styled-components";
//img
import trackingImg from "../../assets/img/service/tracking.png";
import fclImg from "../../assets/img/service/fcl.png";
import lclImg from "../../assets/img/service/lcl.png";
import doorImg from "../../assets/img/service/door.png";
import airImg from "../../assets/img/service/air.png";
import img1 from "../../assets/img/service/BUSINESS_LINE_ICON-15-1-150x150.png";
import img2 from "../../assets/img/service/BUSINESS_LINE_ICON-16-1-150x150.png";
import img3 from "../../assets/img/service/BUSINESS_LINE_ICON_circle-19-150x150.png";
import img4 from "../../assets/img/service/BUSINESS_LINE_ICON_circle-20-150x150.png";
import consolImg from "../../assets/img/service/consol.png";
//utils
import fadeAnimation from "../../utils/fadeAnimation";
import { useEffect, useState } from "react";

const ServiceArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  .article-content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 25%;
    /* min-width: 300px; */
    padding: 10px 10px;
    text-align: center;
    .content-top {
      height: 210px;
      h1 {
        font-size: var(--font-base);
        margin: 20px 0 10px 0;
        font-weight: bolder;
      }
      h2 {
        font-size: var(--font-base);
        font-weight: bolder;
      }
    }
    .line {
      width: 52%;
      height: 1px;
      background-color: white;
      margin: 30px 0;
    }
    p {
      font-size: var(--font-sm);
      line-height: 1.8rem;
    }
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .article-content-wrap {
      width: 100%;
      p {
        font-size: var(--font-sm);
      }
    }
  }
`;

function ServiceArticle({ params }) {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    fadeAnimation();
  });

  let contents = [];
  switch (params) {
    case "sea":
      contents = [
        {
          imgPath: fclImg,
          title: t("FCL"),
          subTitle: t("FCL_subTitle"),
          desc: t("FCL_desc"),
        },
        {
          imgPath: lclImg,
          title: t("LCL"),
          subTitle: t("LCL_subTitle"),
          desc: t("LCL_desc"),
        },
        {
          imgPath: doorImg,
          title: t("DOOR TO DOOR 서비스"),
          subTitle: null,
          desc: t("DoorToDoor_desc"),
        },
        {
          imgPath: trackingImg,
          title: t("실시간 TRACKING"),
          subTitle: null,
          desc: t("TRACKING_desc"),
        },
      ];
      break;
    case "air":
      contents = [
        {
          imgPath: airImg,
          title: t("적시운송서비스"),
          subTitle: null,
          desc: t("적시운송서비스_desc"),
        },
        {
          imgPath: doorImg,
          title: t("DOOR TO DOOR 서비스"),
          subTitle: null,
          desc: t("DoorToDoor_desc"),
        },
        {
          imgPath: trackingImg,
          title: t("실시간 TRACKING"),
          subTitle: null,
          desc: t("TRACKING_desc"),
        },
      ];
      break;
    case "cargo":
      contents = [
        {
          imgPath: img1,
          title: t("믿을 수 있는 안전 운송"),
          subTitle: null,
          desc: t("믿을 수 있는 안전 운송_desc"),
        },
        {
          imgPath: img2,
          title: t("체계적인 원라인 시스템"),
          subTitle: null,
          desc: t("체계적인 원라인 시스템_desc"),
        },
        {
          imgPath: img3,
          title: t("GLS만의 운송 노하우"),
          subTitle: null,
          desc: t("GLS만의 운송 노하우_desc"),
        },
        {
          imgPath: img4,
          title: t("국내 및 로컬 최상의 서비스"),
          subTitle: null,
          desc: t("국내 및 로컬 최상의 서비스_desc"),
        },
      ];
      break;
    case "consol":
      contents = [
        {
          imgPath: consolImg,
          title: t("GLS 그룹의 프리미엄 서비스"),
          subTitle: null,
          desc: t("GLS 그룹의 프리미엄 서비스_desc"),
        },
      ];
      break;
    default:
      break;
  }

  return (
    <ServiceArticleContainer className="service-article-container">
      {contents?.map((item, index) => {
        return (
          <div key={index} className="article-content-wrap">
            <div className="content-top">
              <img className="fade" src={item.imgPath} alt="" />
              <div className="fade">
                <h1>{item.title}</h1>
                <h2>{item.subTitle ? item.subTitle : ""}</h2>
              </div>
            </div>
            <div className="line"></div>
            <p className="fade">{item.desc}</p>
          </div>
        );
      })}
    </ServiceArticleContainer>
  );
}
export default ServiceArticle;
