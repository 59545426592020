import styled from "styled-components";
import logoWhite from "../../assets/img/logo/new_logo_simple_blue.png";
import ServiceArticle from "../common/ServiceArticle";

const ServiceSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  .title {
    margin-bottom: 100px;
    .logo {
      width: 130px;
      img {
        width: 100%;
      }
    }
    h1 {
      font-size: var(--font-xl);
      margin: 10px 0;
      color: var(--yellow);
      font-weight: bolder;
    }
    h2 {
      font-size: var(--font-lg);
      font-weight: bolder;
    }
  }
  .service-article-wrap {
    width: 100%;
  }
`;

function ServiceSection({ title, subTitle, service }) {
  return (
    <ServiceSectionContainer>
      <div className="title">
        <div className="logo">
          {/* <img src={logoWhite} alt="GLS Logo" /> */}
        </div>
        <div>
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
        </div>
      </div>
      <div className="service-article-wrap">
        <ServiceArticle params={service}></ServiceArticle>
      </div>
    </ServiceSectionContainer>
  );
}

export default ServiceSection;
