import { createGlobalStyle } from "styled-components";

const Animations = createGlobalStyle`

    @keyframes fadeInUp {
        0%{transform:translate(0px, 100px); opacity: 0;}
        100%{transform:translate(0px, 0); opacity: 1;}
    }
    .fade {
        opacity: 0;
    }
    .fade-in-up{
        animation: fadeInUp;
        animation-duration:2s;
        animation-fill-mode: forwards;
    }


`;

export default Animations;
