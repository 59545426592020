import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
// img
import koreaFlag from "../assets/img/flag/korea.png";
import chinaFlag from "../assets/img/flag/china.png";
import hongkongFlag from "../assets/img/flag/hongkong.png";
import japanFlag from "../assets/img/flag/japan.png";
import vietnamFlag from "../assets/img/flag/vietnam.png";
import usaFlag from "../assets/img/flag/usa.png";
import logoBlue from "../assets/img/logo/glsconsol_v.png";

const HeaderContainer = styled.header`
  @media all and (max-width: 767px) {
    display: none;
  }
  @keyframes fadeInUp {
    0% {
      transform: translate(0px, 4px);
      opacity: 0;
    }
    100% {
      transform: translate(0px, 0);
      opacity: 1;
    }
  }
  height: 120px;
  box-sizing: border-box;
  border-bottom: 2px solid var(--yellow);
  background-color: white;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 999;

  .gnv-wrap {
    height: 100%;
    .topbar {
      height: 30%;
      background-color: var(--gray);
      .topbar-content-wrap {
        max-width: var(--max-width);
        height: 100%;
        margin: auto;
        padding: 0 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .flag-wrap {
          width: 300px;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .flag {
            width: 30px;
            height: 100%;
            cursor: pointer;
            padding: 0;
            border: none;
            outline: none;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .navbar {
      height: 70%;
      text-decoration: none;
      .active {
        color: var(--yellow);
      }
      .navbar-content-wrap {
        max-width: var(--max-width);
        height: 100%;
        margin: auto;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .logo {
          width: 160px;
          height: 80%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .menu-wrap {
          width: 500px;
          height: 100%;

          a:hover {
            color: var(--yellow);
          }
          & > ul {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 1;
            .menu-item {
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
          .submenu-item {
            display: none;
            position: absolute;
            bottom: -116%;
            left: -12px;
            width: 200px;
            z-index: 1;
            animation: fadeInUp;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            li {
              background-color: #181828;
              line-height: 3rem;
              text-indent: 10px;
              a {
                color: white;
                &:hover {
                  color: var(--yellow);
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Header() {
  const { t, i18n } = useTranslation();
  const [browserLanguage, setBrowserLanguage] = useState();
  const [i18Language, setI18Language] = useState();
  const [isHover, setIsHover] = useState("none");

  useEffect(() => {
    // console.log("browser언어:", window.navigator.language);
    setBrowserLanguage(window.navigator.language.slice(0, 2));
  }, [browserLanguage]);

  const handleLanguageChange = (e) => {
    // console.log("클릭된 언어:", e.target.dataset.language);
    i18n.changeLanguage(e.target.dataset.language);
    setI18Language(i18n.language);
  };

  const handleMenuMouseOver = (e) => {
    setIsHover("block");
  };
  const handleMenuMouseOut = (e) => {
    setIsHover("none");
  };

  return (
    <HeaderContainer className="gnv">
      <div className="gnv-wrap">
        <section className="topbar">
          <div className="topbar-content-wrap">
            <div className="flag-wrap">
              <button className="flag" onClick={handleLanguageChange}>
                <img src={koreaFlag} alt="korea" data-language="ko" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={vietnamFlag} alt="vietnam" data-language="en" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={japanFlag} alt="japan" data-language="ja" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={hongkongFlag} alt="hongkong" data-language="en" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={chinaFlag} alt="china" data-language="zh" />
              </button>
              <button className="flag" onClick={handleLanguageChange}>
                <img src={usaFlag} alt="usa" data-language="en" />
              </button>
            </div>
          </div>
        </section>
        <section className="navbar">
          <nav className="navbar-content-wrap">
            <div className="logo">
              <Link to="/">
                <img src={logoBlue} alt="GLS logoBlue" />
              </Link>
            </div>
            <div className="menu-wrap">
              <ul>
                <li
                  className="menu-item"
                  onMouseOver={handleMenuMouseOver}
                  onMouseOut={handleMenuMouseOut}
                >
                  <NavLink to="corpinfo">{t("회사소개")}</NavLink>
                  <ul className="submenu-item" style={{ display: isHover }}>
                    <li>
                      <NavLink to="corpinfo">{t("개요")}</NavLink>
                    </li>
                    <li>
                      <NavLink to="corpinfo/family">{t("GLS Family")}</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="navbar-menu-item">
                  <NavLink to="service">{t("서비스")}</NavLink>
                </li>
                <li className="navbar-menu-item">
                  <NavLink
                    to="*"
                    onClick={(e) => {
                      e.preventDefault();
                      alert(t("회원전용"));
                    }}
                  >
                    {t("E-TRACKING")}
                  </NavLink>
                </li>
                <li className="navbar-menu-item">
                  <NavLink to="qna">{t("문의하기")}</NavLink>
                </li>
                {i18Language === "ko" || browserLanguage === "ko" ? (
                  <li className="navbar-menu-item">
                    <NavLink to="consolbus">{t("CONSOLBUS")}</NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </nav>
        </section>
      </div>
    </HeaderContainer>
  );
}

export default Header;
