import { NavLink, Outlet } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components";
//img
import bgImg from "../assets/img/consolbus/consol_bg1.jpg";
import consolbus from "../assets/img/consolbus/consolBus_color-300x110.png";
import title1 from "../assets/img/consolbus/title-1-592x30.png";
//utils
import fadeAnimation from "../utils/fadeAnimation";
//component
import TopContainer from "../components/TopContainer";

const ConsolbusContainer = styled.div`
  .content-top {
    max-width: var(--max-width);
    padding: 60px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      margin: 10px 0;
    }
    .desc {
      margin: 20px 0;
      line-height: 1.5rem;
      text-align: center;
    }
    .btn-group {
      margin: 60px 0;
    }
  }
  .cotent-bottom {
    width: 100vw;
    background-color: var(--gray);
    .content-wrap {
      max-width: var(--max-width);
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 120px 0;
      a {
        background-color: var(--consol-main);
        color: white;
        margin-top: 30px;
        &:hover {
          background-color: transparent;
          color: var(--consol-main);
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .content-top {
      padding: 40px 0 20px 0;
      .title {
        width: 100vw;
        box-sizing: border-box;
        padding: 0 20px;
        img {
          width: 100%;
        }
      }
    }
  }
`;

const ConsolbusBtn = styled(NavLink)`
  margin: 0 8px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid var(--consol-main);
  color: var(--consol-main);
  outline: none;
  padding: 14px 30px;
  &.active {
    background-color: var(--consol-main);
    color: white;
  }
  &:hover {
    background-color: var(--consol-main);
    color: white;
  }
  @media all and (max-width: 767px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

function Consolbus() {
  fadeAnimation();

  return (
    <ConsolbusContainer>
      <TopContainer
        bgImg={bgImg}
        wrap={`GLS CONSOL BUS`}
        desc={
          "복잡한 비용은 No! 청구항목은 단 하나! GLS CONSOL BUS를 소개합니다."
        }
      ></TopContainer>
      <div className="content-top">
        <div className="title fade">
          <img src={title1} alt="NEW CONSOLIATION SYSTEM" />
        </div>
        <div className="desc fade">
          <p>복잡한 비용은 No! 청구항목은 단 하나!</p>
          <p>GLS CONSOL BUS를 소개합니다.</p>
        </div>
        <div className="fade">
          <img src={consolbus} alt="consolbus" />
        </div>
        <div className="btn-group">
          <ConsolbusBtn to="/consolbus/">소개</ConsolbusBtn>
          <ConsolbusBtn to="/consolbus/advantage">장점</ConsolbusBtn>
          <ConsolbusBtn to="http://www.consolbus.com/" target="_blank">
            콘솔버스 예약하기
          </ConsolbusBtn>
        </div>
      </div>

      {/* 소개, 장점 components */}
      <Outlet />

      <div className="cotent-bottom">
        <div className="content-wrap">
          <img src={consolbus} alt="consolbus" />
          <ConsolbusBtn to="http://www.consolbus.com/" target="_blank">
            콘솔버스 예약하기
          </ConsolbusBtn>
        </div>
      </div>
    </ConsolbusContainer>
  );
}

export default Consolbus;
