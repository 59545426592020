import { styled } from "styled-components";
import { useEffect } from "react";

import Flag_cn from "../../assets/img/flag/china.png";
import Flag_hk from "../../assets/img/flag/hongkong.png";
import Flag_jp from "../../assets/img/flag/japan.png";
import Flag_kr from "../../assets/img/flag/korea.png";
import Flag_vn from "../../assets/img/flag/vietnam.png";
import fadeAnimation from "../../utils/fadeAnimation";

const FamilyContainer = styled.div`
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(3, minmax(100px, auto));
  max-width: var(--max-width);
  margin: auto;
  @media all and (max-width: 767px) {
    grid-template: none;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media all and (max-width: 767px) {
    grid-template: none;
  }
`;
const Col = styled.div``;

const H1 = styled.h1`
  font-weight: bold;
  font-size: 1.8rem;
  margin: 2rem;
  text-align: center;
`;

const H2 = styled.h2`
  font-size: 1rem;
  margin: 30px;
  text-align: center;
  line-height: 2rem;
`;

const Card = styled.div`
  background-color: #f4f6f7;
  background-position: center top;
  border-radius: 50px;
  padding: 40px;
  height: 430px;
  margin: 1rem;
  text-align: start;
  img {
    width: 3rem;
    height: 1.5rem;
    padding: 10px 0;
  }
`;

const City = styled.div`
  font-size: var(--font-lg);
  font-weight: bold;
  padding: 10px 0;
`;

const Country = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Address = styled.div`
  /* font-weight: lighter; */
  color: var(--font-gray);
  margin-bottom: 1rem;
  line-height: 2rem;
`;

const TEL = styled.div`
  /* font-weight: lighter; */
  color: var(--font-gray);
  margin-bottom: 1rem;
  line-height: 2rem;
`;

function FamilySection() {
  useEffect(() => {
    fadeAnimation();
  });
  return (
    <FamilyContainer>
      <H1>GLOBAL LOGISTICS NETWORK</H1>
      <H2>
        홍콩, 베트남 호치민, 하이퐁, 하노이, 중국 상해, 일본 지역에 현지 지사를
        두고 있으며,<br></br> 전 세계 주요 항구의 파트너들과 함께 하고 있습니다.
      </H2>

      <Container>
        <Row>
          <Col></Col>
          <Col>
            <Card className="fade">
              <img src={Flag_kr} alt="KR" />
              <City>SEOUL</City>
              <Country>REP. OF KOREA</Country>
              <Address>
                대한민국 서울특별시 강서구 양천로 551-24, 803호~806호
                (가양동,한화비즈메트로2차) 우 : 07532
              </Address>
              <TEL>
                TEL : +82-2-3775-1180<br></br>FAX : +82-2-3775-1181
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_kr} alt="KR" />
              <City>INCHEON</City>
              <Country>REP. OF KOREA</Country>
              <Address>대한민국 인천광역시 서해대로 210번길 45 301호</Address>
              <TEL>
                TEL : +82-32-889-4026<br></br>
                FAX : +82-32-889-4027
              </TEL>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Card className="fade">
              <img src={Flag_hk} alt="KR" />
              <City>HONGKONG</City>
              <Country>CHINA</Country>
              <Address>
                Room F, 20/F, Reason Group Tower, 403-413 Castle Peak Road, Kwai
                Chung, New Territories, Hong Kong
              </Address>
              <TEL>
                TEL : 852-24333716<br></br> FAX : 852-24333076
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_vn} alt="KR" />
              <City>HOCHIMINH</City>
              <Country>VIETNAM</Country>
              <Address>
                6FL, SOHUDE TOWER, 331 NGUYEN TRONG TUYEN STREET, WARD 10, PHU
                NHUAN DIST, HCMC, VIETNAM
              </Address>
              <TEL>
                TEL : 84-225-3-555-379<br></br>
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_vn} alt="KR" />
              <City>HAIPHONG</City>
              <Country>VIETNAM</Country>
              <Address>
                ROOM 415+416, DAU KHI BUILDING (Tòa 4 tầng cũ), 441 DA NANG
                STREET, DONG HAI 1 WARD, HAI AN DISTRICT, HAI PHONG CITY.
              </Address>
              <TEL>TEL : 84-225-3-555-379</TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_vn} alt="KR" />
              <City>HANOI</City>
              <Country>VIETNAM</Country>
              <Address>
                ROOM 506, 5FL, 535 KIM MA STREET, BA DINH DISTRICT, HANOI,
                VIETNAM
              </Address>
              <TEL>
                TEL : 84-04-6274-1601 / 1605<br></br>FAX : 84-04-6274-1609
              </TEL>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="fade">
              <img src={Flag_cn} alt="KR" />
              <City>SHENZHEN</City>
              <Country>CHINA</Country>
              <Address>
                ROOM 2416, CUNJIN BUILDING, NO.3005 SOUTH DONGMEN ROAD, LUOHU
                DISTRICT, SHENZHEN, GUANGDONG, CHINA
              </Address>
              <TEL>
                TEL : 86-0755-8215-7011#801<br></br>FAX : 86-0755-8215-7230
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_cn} alt="KR" />
              <City>SHANGHAI</City>
              <Country>CHINA</Country>
              <Address>
                RM.2008-2009, PIAOYING BLDG, TIANBAO ROAD 578, HONGKOU DISTRICT,
                SHANGHAI, CHINA
              </Address>
              <TEL>
                TEL : 86-21-5557-1001#8002<br></br>FAX : 86-21-5512-6911
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_cn} alt="KR" />
              <City>TIANJIN</City>
              <Country>CHINA</Country>
              <Address>
                RM B-1802, JINGCAI BLDG, DAGU SOUTH ROAD 459, HEXI DISTRICT,
                TIANJIN,CHINA
              </Address>
              <TEL>
                TEL : +82-2-3775-1180<br></br>FAX : +82-2-3775-1181
              </TEL>
            </Card>
          </Col>
          <Col>
            <Card className="fade">
              <img src={Flag_jp} alt="KR" />
              <City>TOKYO</City>
              <Country>JAPAN</Country>
              <Address>
                HULIC&NEW SHINBASHI BUILDING BUREX FIVE 1003, 11-10, SHINBASHI
                2-CHOME, MINATO-KU, TOKYO, 105-0004, JAPAN
              </Address>
              <TEL>
                TEL : 03-6868-5570<br></br>FAX : 03-6685-2917
              </TEL>
            </Card>
          </Col>
        </Row>
      </Container>
    </FamilyContainer>
  );
}

export default FamilySection;
