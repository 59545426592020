/**기본스타일 reset*/
import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
     ${reset};

     :root{
        --font-xs: 0.85rem;
        --font-sm: 1rem;
        --font-base: 1.2rem;
        --font-medium : 1.4rem;
        --font-lg: 1.8rem;
        --font-xl: 2rem;
        --font-2xl: 4rem;

        --font-gray: #C4C4C4;

        --main-color : rgb(92,194,208);
        --bg-blue : rgb(0,82,147);
        --gray : rgb(240,243,242);
        --gray2 : rgb(247,247,247);
        --gray3 : #F4F6F7;
        --hover-gray: rgb(220,220,220);
        --dark-gray :#EBEBEB;
        --yellow : #FFCC00;
        --black : rgb(24,24,40);
        --blue : rgb(39,72,139);
        --dark-blue: #181828;
        --font-gray: rgb(95,114,127);
        --consol-main : rgb(3,106,145);

        --max-width : 1212px;
     }

     html,body,button,a {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 16px;
      }

     a{
        text-decoration: none;
        color: #002157;
        cursor: pointer;
        font-weight: bolder;
        &:hover{
              color: $yellow;
        }
        .link-active{
              color: $yellow;
        }
     }

    /* @media all and (min-width:1025px) and (max-width:1200px){

    }

    @media all and (min-width:768px) and (max-width:1024px){

    }
    @media all and (max-width:767px){
        #gnv{
            display: none;
        }
        #gnv-mobile{
            display: block;
        }
    } */

    /* @keyframes fadeInUp {
        0%{transform:translate(0px, 100px); opacity: 0;}
        100%{transform:translate(0px, 0); opacity: 1;}
    }
    .fade {
        opacity: 0;
    }
    .fade-in-up{
        animation: fadeInUp;
        animation-duration:2s;
        animation-fill-mode: forwards;
    } */


`;

export default GlobalStyles;
