import styled from "styled-components";
//img
import rebate from "../../assets/img/consolbus/rebate-160x88.png";
import baloons from "../../assets/img/consolbus/baloons-copy-756x196.png";
import artboard1 from "../../assets/img/consolbus/Artboard-1-copy-800x500.png";
import artboard2 from "../../assets/img/consolbus/Artboard-2-240x300.png";
import artboard3 from "../../assets/img/consolbus/Artboard-3.png";
import steps from "../../assets/img/consolbus/steps.png";
//utils
import fadeAnimation from "../../utils/fadeAnimation";
import { useEffect } from "react";

const IntroContainer = styled.div`
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title {
    padding: 40px 0;
    .title-top {
      font-size: var(--font-lg);
      color: var(--consol-main);
      font-weight: bolder;
      padding-bottom: 20px;
    }
    h1 {
      font-size: var(--font-lg);
      font-weight: bolder;
    }
    h2 {
      font-size: var(--font-medium);
      color: var(--font-gray);
      margin-top: 10px;
    }
  }
  .title-desc {
    margin: 20px 0 10px 0;
    p {
      color: var(--font-gray);
      line-height: 2rem;
    }
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .rebate,
    .truck {
      width: 40%;
    }
  }
  .signiture {
    width: 100vw;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media all and (max-width: 767px) {
    .title-desc {
      width: 90%;
    }
    .img-wrap {
      width: 100vw;
      box-sizing: border-box;
      img {
        width: 90%;
      }
      .rebate,
      .truck {
        width: 50%;
      }
    }
  }
`;

function Intro() {
  useEffect(() => {
    fadeAnimation();
  });

  return (
    <IntroContainer>
      <div className="title">
        <h2 className="title-top">Introduction</h2>
        <h1>LCL 시장 정상화</h1>
        <h2>상식이 통하는 물류</h2>
      </div>

      <div className="title-desc">
        <p>현재 아시아 물류시장은 불합리한 리베이트가 성행하고 있습니다.</p>
        <p>
          특히, 창고료가 과다하게 청구되고 잦은 요율 상승과 항목 추가 등은
          화주들 뿐만 아니라 한국 물류업계 전체에 악영향을 주고있습니다.
        </p>
      </div>
      <div className="title-desc">
        <p>
          콘솔버스는 이러한 불합리한 관행을 깨고 ‘LCL 시장 정상화-상식이 통하는
          물류’라는 슬로건으로 물류시장 정상화를 선도합니다.
        </p>
        <p>
          LCL 시장에 만연한 ‘창고-포워더-파트너’간의 창고료 리베이트 관행을 깨고
          합리적인 시장을 만듭니다.
        </p>
      </div>
      <div className="img-wrap">
        <img className="fade rebate" src={rebate} alt="" />
        <img className="fade" src={baloons} alt="" />
      </div>

      <div className="title">
        <h1>콘솔버스 노선</h1>
        <h2>상해,홍콩,심천</h2>
      </div>
      <div className="img-wrap">
        <img className="fade" src={artboard1} alt="" />
        <img className="fade truck" src={artboard2} alt="" />
      </div>
      <div className="title">
        <h1>4단계 올인원 시스템</h1>
        <h2>4-STEPS benefits</h2>
      </div>
      <div className="img-wrap">
        <img className="fade" src={steps} alt="" />
      </div>

      <div className="title">
        <h1>기존방식과의 차별화</h1>
        <h2>콘솔버스만의 주요 특징</h2>
      </div>
      <div className="img-wrap signiture ">
        <img src={artboard3} alt="" />
      </div>
    </IntroContainer>
  );
}

export default Intro;
