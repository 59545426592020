import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import localeKO from "./lang.ko.json";
import localeEN from "./lang.en.json";
import localeJP from "./lang.jp.json";
import localeCH from "./lang.ch.json";

const resource = {
  en: { translation: localeEN },
  ko: { translation: localeKO },
  zh: { translation: localeCH },
  ja: { translation: localeJP },
};

const init = (lang) => {
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      resources: resource,
      fallbackLng: "en-US",
      debug: true,
      interpolation: {
        escapeValue: true,
        prefix: "{",
        suffix: "}",
      },
      lng: lang,
    });
};

export default init;
