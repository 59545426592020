import styled from "styled-components";
//img,icons
import glsLogo from "../../assets/img/logo/glsconsol_h_white.png";

const FooterENContainer = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family {
      color: white;
      display: flex;
      flex-wrap: wrap;
      .family-item {
        width: 50%;
        box-sizing: border-box;
        padding: 30px;
        h1 {
          font-size: var(--font-base);
          font-weight: bolder;
          padding-bottom: 4px;
        }
        p {
          line-height: 1.6rem;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 20px 0;
    }
    .footer-bottom {
      .logo {
        width: 180px;
        margin: auto;
        img {
          width: 100%;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .content {
      .family {
        flex-direction: column;
        .family-item {
          width: 100%;
          padding: 20px 0;
        }
      }
    }
  }
`;

function FooterEN() {
  const family = [
    {
      id: "hongkong",
      name: "HONG KONG",
      address:
        "Room F, 20/F, Reason Group Tower, 403-413 Castle Peak Road, Kwai Chung, New Territories, Hong Kong",
      tel: "852-24333716 ",
      fax: "852-24333076",
    },
    {
      id: "hochiminh",
      name: "HOCHIMINH, VIETNAM",
      address:
        "6FL, SOHUDE TOWER, 331 NGUYEN TRONG TUYEN STREET, WARD 10, PHU NHUAN DIST, HCMC, VIETNAM",
      tel: "225-3-555-379",
      fax: "",
    },
    {
      id: "haiphong",
      name: "HAIPHONG, VIETNAM",
      address:
        "ROOM 415+416, DAU KHI BUILDING (Tòa 4 tầng cũ), 441 DA NANG STREET, DONG HAI 1 WARD, HAI AN DISTRICT, HAI PHONG CITY.",
      tel: "84-225-3-555-379",
      fax: "",
    },
    {
      id: "hanoi",
      name: "HANOI, VIETNAM",
      address:
        "ROOM 506, 5FL, 535 KIM MA STREET, BA DINH DISTRICT, HANOI, VIETNAM",
      tel: " 84-04-6274-1601/1605",
      fax: "84-04-6274-1609",
    },
  ];

  return (
    <FooterENContainer>
      <div className="content">
        <div className="family">
          {family.map((v, i) => {
            return (
              <div key={v.id} className="family-item">
                <h1>{v.name}</h1>
                <p>{v.address}</p>
                <p>Tel : {v.tel}</p>
                {v.fax ? <p>Fax : {v.fax}</p> : ""}
              </div>
            );
          })}
        </div>
        <div className="line"></div>
        <div className="footer-bottom">
          <div className="logo">
            <img src={glsLogo} alt="" />
          </div>
          {/* <p>© Copyright 2019 GLS Co.,Ltd. All Rights Reserved.</p> */}
        </div>
      </div>
    </FooterENContainer>
  );
}

export default FooterEN;
