import styled from "styled-components";
//img
import artboard5 from "../../assets/img/consolbus/Artboard-5-600x600.png";
import arrow from "../../assets/img/consolbus/ico_arrow-70x70.png";
import artboard6 from "../../assets/img/consolbus/Artboard-6-500x60.png";
import baloons3 from "../../assets/img/consolbus/baloons-copy-3-756x196.png";
import ship from "../../assets/img/consolbus/icon-300x274.png";
//utils
import fadeAnimation from "../../utils/fadeAnimation";
import { useEffect } from "react";

const AdvantageContainer = styled.div`
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title {
    .title-top {
      font-size: var(--font-lg);
      color: var(--consol-main);
      font-weight: bolder;
      padding-bottom: 20px;
    }
    h2 {
      font-size: var(--font-medium);
      color: var(--font-gray);
    }
    h1 {
      font-size: var(--font-lg);
      font-weight: bolder;
      margin: 20px 0;
    }
    .title-desc {
      margin: 20px 0 20px 0;
      p {
        color: var(--font-gray);
        line-height: 2rem;
      }
    }
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    img {
      margin: 10px 0;
    }
    h1 {
      font-size: var(--font-lg);
      font-weight: bolder;
      margin-top: 30px 0;
    }
    h2 {
      font-size: var(--font-medium);
      color: var(--font-gray);
      margin-top: 10px;
    }
    p {
      line-height: 2rem;
      color: var(--font-gray);
    }
  }
  @media all and (max-width: 767px) {
    .img-wrap {
      width: 100vw;
      img {
        width: 90%;
      }
      .arrow {
        width: 20%;
      }
    }
  }
`;

function Advantage() {
  useEffect(() => {
    fadeAnimation();
  });

  return (
    <AdvantageContainer>
      <div className="title">
        <h2 className="fade title-top">Advantages</h2>
        <h1 className="fade">간단하고 실속있게</h1>
        <h2 className="fade">EASY & SIMPLE</h2>

        <div className="fade title-desc">
          <p>
            Bunker Adjustment Factor, Cost Recovery Factor… 등 많고 복잡한
            요율들을
          </p>
          <p>이제 간단하게 하나로, 그리고 저렴하게 이용가능합니다.</p>
        </div>
      </div>
      <div className="img-wrap">
        <img className="fade" src={artboard5} alt="charges" />
        <img className="fade arrow" src={arrow} alt="arrow icon" />
        <img className="fade" src={artboard6} alt="seat charge" />
        <img className="fade" src={baloons3} alt="1seat=2cbm" />
        <div className="fade">
          <h1>화물 배상 책임 보험</h1>
          <h2>데미지 발생시 CI금액 110% 보상</h2>
        </div>
        <img className="fade" src={ship} alt="ship" />
        <p>
          데미지가 발생하여 화물을 쓸 수 없게 되었을 때, CI 금액의 110%를 보상해
          드립니다.
        </p>
        <p>
          현지 창고직원을 통해 컨테이너에 적재하며,화물 적재과정을
          모니터링하고있습니다.
        </p>
        <p>
          GLS 만의 컨테이너 내 개별 화물을 위한 완충장비를 개발하여 이를
          적용하였습니다.
        </p>
      </div>
    </AdvantageContainer>
  );
}

export default Advantage;
