import { useTranslation } from "react-i18next";
import styled from "styled-components";
//img
import glsLogo from "../assets/img/logo/GLS-logo-blue.png";
import glsLogoYellow from "../assets/img/logo/GLS-logo-yellow.png";
import glsLogo_black from "../assets/img/logo/glsconsol_v_sm.png";
import glsLogo_white from "../assets/img/logo/glsconsol_v_white.png";
import glsLogoSimpleBlue from "../assets/img/logo/new_logo_simple_blue.png";
import processBg1 from "../assets/img/home/process_bg1.jpg";
import processBg2 from "../assets/img/home/process_bg2.jpg";
import processBg3 from "../assets/img/home/process_bg3.jpg";
import processBg4 from "../assets/img/home/process_bg4.jpg";
import processBg5 from "../assets/img/home/process_bg5.jpg";
import homeSectionSea from "../assets/img/home/home_serction_sea.jpg";
import homeSectionAir from "../assets/img/home/home_serction_air.jpg";
import homeSectionCargo from "../assets/img/home/home_serction_cargo.jpg";
import homeSectionConsol from "../assets/img/home/home_serction_consol.jpg";
import worldTracking from "../assets/img/home/world_tracking.png";
//componets
import Slider from "../components/home/Slider";
import ProcessLine from "../components/home/ProcessLine";
import ServiceSection from "../components/home/ServiceSection";
import { useEffect, useState } from "react";
//utils
import fadeAnimation from "../utils/fadeAnimation";

const HomeContainer = styled.div`
  .home-container {
    width: 100%;
    section {
      width: 100vw;
      padding: 40px 0;
      /* height: 860px; */
      display: flex;
      justify-content: center;
      align-items: center;
      .content-wrap {
        max-width: var(--max-width);
        margin: auto;
      }
    }
    section.slider {
      height: 700px;
      padding: 0;
      .content-wrap {
        max-width: 100vw;
        margin: 0px;
        height: 100%;
      }
    }
    section.service {
      padding: 0;
      display: grid;
      align-items: normal;
      gap: 0;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      max-width: 100vw;
      .grid-item {
        position: relative;
        .title {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          .title-logo {
            width: 180px;
            img {
              width: 100%;
            }
          }
          h1 {
            font-size: var(--font-xl);
            color: var(--yellow);
            font-weight: bolder;
            margin: 10px 0 20px 0;
          }
          p {
            line-height: 2rem;
            color: white;
          }
        }
        span,
        .grid-logo {
          color: white;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: var(--font-medium);
          font-weight: bolder;
          img {
            width: 50%;
          }
        }
      }
      & .grid-item:nth-child(1) {
        grid-column: 1/4;
        grid-row: 1;
        background-color: var(--bg-blue);
        padding: 80px 0;
      }
      & .grid-item:nth-child(2) {
        background: url(${processBg1}) no-repeat center;
        background-size: cover;
      }
      & .grid-item:nth-child(3) {
        background: url(${processBg2}) no-repeat center;
        background-size: cover;
      }
      & .grid-item:nth-child(4) {
        background: url(${processBg3}) no-repeat center;
        background-size: cover;
      }
      & .grid-item:nth-child(5) {
        background: url(${processBg4}) no-repeat center;
        background-size: cover;
      }
      & .grid-item:nth-child(6) {
        background: url(${processBg5}) no-repeat center;
        background-size: cover;
      }
      & .grid-item:nth-child(7) {
        background-color: black;
      }
    }
    section.process {
      background-color: var(--gray2);
      text-align: center;
      .content-wrap {
        padding: 30px 0;
        .title {
          h1 {
            margin: 10px 0 20px 0;
            font-size: var(--font-xl);
            font-weight: bolder;
          }
        }
      }
    }
    section.sea {
      background: url(${homeSectionSea}) no-repeat center;
      background-size: cover;
      .content-wrap {
        margin: 0;
      }
    }
    section.air {
      background: url(${homeSectionAir}) no-repeat center;
      background-size: cover;
      .content-wrap {
        margin: 0;
      }
    }
    section.cargo {
      background: url(${homeSectionCargo}) no-repeat center;
      background-size: cover;
      .content-wrap {
        margin: 0;
      }
    }
    section.consol {
      background: url(${homeSectionConsol}) no-repeat center;
      background-size: cover;
      .content-wrap {
        margin: 0;
      }
    }
    section.tracking {
      .content-wrap {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title-logo {
            width: 300px;
            img {
              width: 100%;
            }
          }
          h6 {
            font-size: var(--font-lg);
            margin: 20px 0 6px 0;
          }
          h1 {
            font-size: var(--font-2xl);
            margin: 10px 0;
            font-weight: bolder;
          }
        }
        .content {
          display: flex;
          justify-content: center;
          padding: 40px 0;
          .content-img {
            width: 45%;
            img {
              width: 100%;
            }
          }
          .text {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p {
              line-height: 1.8rem;
              text-align: left;
              font-size: var(--font-base);
            }
            button {
              padding: 10px 12px;
              margin-top: 40px;
              font-size: var(--font-medium);
              background-color: var(--yellow);
              border: none;
              border-radius: 6px;
              outline: none;
              cursor: pointer;
              font-weight: bolder;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .home-container {
      section.slider {
        width: 100vw;
        padding: 0;
        height: 660px;
      }
      section.service {
        display: flex;
        flex-direction: column;
        width: 100vw;
        .grid-item {
          width: 100vw;
          height: 200px;
          p {
            padding: 0 16px;
          }
        }
        & .grid-item:first-child {
          padding: 100px 0;
        }
        & .grid-item:last-child {
          display: none;
        }
      }
      section.tracking {
        background-color: #f0f4f6;
        .content-wrap {
          .content {
            flex-direction: column;
            width: 90%;
            margin: auto;
            .content-img {
              width: 90%;
              margin: 16px 0;
              img {
                width: 100%;
              }
            }
            .text {
              width: 100%;
              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

function Home() {
  const { t } = useTranslation();
  // let [isMobile, setIsMobile] = useState();

  useEffect(() => {
    fadeAnimation();
    // let mobile = /Mobi/i.test(window.navigator.userAgent);
    // setIsMobile(mobile);
  }, []);

  return (
    <HomeContainer>
      <div className="home-container">
        <section className="slider">
          <div className="content-wrap">
            <Slider></Slider>
          </div>
        </section>
        <section className="service">
          <div className="grid-item">
            <div className="title">
              <div className="title-logo">
                <img src={glsLogo_white} alt="GLS Logo" />
              </div>
              <h1 className="fade">SERVICES</h1>
              <div className="fade">
                <p>{t("services_desc1")}</p>
                <p>{t("services_desc2")}</p>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <span className="fade">{t("글로벌네트워크")}</span>
          </div>
          <div className="grid-item">
            <span className="fade">{t("해상수출입")}</span>
          </div>
          <div className="grid-item">
            <span className="fade">{t("육상수출입")}</span>
          </div>
          <div className="grid-item">
            <span className="fade">{t("항공수출입")}</span>
          </div>
          <div className="grid-item">
            <span className="fade">{t("전세계 최대 전산망 보유")}</span>
          </div>
          <div className="grid-item">
            <div className="grid-logo">
              <img src={glsLogo_white} alt="GLS Logo" />
            </div>
          </div>
        </section>
        <section className="process">
          <div className="content-wrap">
            <div className="title">
              <img src={glsLogo_black} alt="GLS logo" />
              <h1>PROCESS</h1>
            </div>
            <div className="process-line">
              <ProcessLine></ProcessLine>
            </div>
          </div>
        </section>
        <section className="sea">
          <div className="content-wrap">
            <ServiceSection
              title={"SEA"}
              subTitle={t("해상운송")}
              service={"sea"}
            ></ServiceSection>
          </div>
        </section>
        <section className="air">
          <div className="content-wrap">
            <ServiceSection
              title={"AIR"}
              subTitle={t("항공운송")}
              service={"air"}
            ></ServiceSection>
          </div>
        </section>
        <section className="cargo">
          <div className="content-wrap">
            <ServiceSection
              title={"CARGO"}
              subTitle={t("벌크카고")}
              service={"cargo"}
            ></ServiceSection>
          </div>
        </section>
        <section className="consol">
          <div className="content-wrap">
            <ServiceSection
              title={"CONSOL"}
              subTitle={t("콘솔업무")}
              service={"consol"}
            ></ServiceSection>
          </div>
        </section>
        <section className="tracking">
          <div className="content-wrap">
            <div className="title">
              <div className="title-logo">
                <img src={glsLogoYellow} alt="GLS Logo" />
              </div>
              <h6>{t("실시간Tracking서비스")}</h6>
              <h1>{t("TRACKING FREIGHT")}</h1>
            </div>
            <div className="content">
              <div className="content-img fade">
                <img src={worldTracking} alt="" />
              </div>
              <div className="text fade">
                <div>
                  <p>{t("tracking_desc1")}</p>
                  <br />
                  <p>{t("tracking_desc2")}</p>
                </div>
                <button
                  className="fade"
                  onClick={(e) => {
                    e.preventDefault();
                    alert(t("회원전용"));
                  }}
                >
                  {t("tracking_btn")}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HomeContainer>
  );
}

export default Home;
