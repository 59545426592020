import { createGlobalStyle } from "styled-components";

const ResponsiveStyles = createGlobalStyle`
    @media all and (min-width:1025px) and (max-width:1200px){

    }

    @media all and (min-width:768px) and (max-width:1024px){
 
    }
    @media all and (max-width:767px){
        html{
            font-size: 14px;
        }
        section.service{

        }
        section.process{
            .title{
                h1{
                    font-size:var(--font-base);
                }
            }
            .process-line-container{
                max-width: 90%;
                margin: auto;
                flex-direction: column;
                .process-item{
                    width: 100%;
                    .img-wrap{
                        width: 40%;
                    }
                }
            }
        }

        section.sea,
        section.air,
        section.cargo,
        section.consol{
            height: auto;
            background-size: fill;
            overflow: hidden;
            .service-article-container{
                flex-direction: column;
                .article-content-wrap{
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 20px;
                    margin-bottom: 10px;
                }
            }
        }
    }


`;

export default ResponsiveStyles;
