import React from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  height: "400px",
};

const center = {
  lat: 37.4450724,
  lng: 126.6280573,
};

function IntroduceMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEOmgidvM9WTfaSQ1sTQPl19Lcd3fXi8w",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} onLoad={onLoad} onUnmount={onUnmount}>
      <MarkerF position={center} icon={{ url: (require('../../assets/img/marker.png')), scale: 1 }}></MarkerF>
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(IntroduceMap);
