import styled from "styled-components";
import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import ScrollTopBtn from "./components/common/ScrollTopBtn";

const OutletContainer = styled.div`
  margin-top: 120px;
`;

function App() {
  return (
    <>
      <Header></Header>
      <HeaderMobile></HeaderMobile>
      <OutletContainer>
        <Outlet />
      </OutletContainer>
      <ScrollTopBtn />
      <Footer></Footer>
    </>
  );
}
export default App;
