import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { BiMap, BiPhone, BiTime } from "react-icons/bi";

import img from "../../assets/img/info/introduce_detail.jpg";
import img_our from "../../assets/img/info/introduce_our.jpg";
import img_map from "../../assets/img/logo/glsconsol_v_sm.png";
import logo_white from "../../assets/img/logo/glsconsol_v_white.png";
import fadeAnimation from "../../utils/fadeAnimation";

const DetailImg = styled.div`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Blank = styled.div`
  height: ${(props) => props.ht};
`;
const H1 = styled.div`
  font-size: 3rem;
  text-align: start;
  line-height: 3.6rem;
  img {
    margin: 20px 0;
  }
  span {
    color: var(--yellow);
  }
`;
const H2 = styled.div`
  font-size: 2.5rem;
  text-align: start;
  margin: 20px 0;
`;

const H4 = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 120px;
  text-align: center;
`;
const H3 = styled.div`
  font-size: 2.5rem;
  margin: 20px 0 40px 0;
`;

const Map = styled.div`
  margin-bottom: 600px;
`;

const Wrap = styled.div`
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media all and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding-left: 21px;
  padding-right: 21px;
  color: white;
`;

const Download = styled.div`
  color: var(--yellow);
  font-size: 1.5rem;
  font-weight: bold;
  border: 2px solid var(--yellow);
  padding: 1rem;
  margin: auto;
  width: 50%;
  margin-left: 0;
  margin-top: 10px;
  @media all and (max-width: 767px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const Info = styled.div`
  text-align: start;
  line-height: 2rem;
  font-size: var(--font-base);
`;

const Our = styled.div`
  background-color: ${(props) => props.bg};
  padding: 30px;
  color: white;
  text-align: start;
  /* height: 400px; */
`;
const Our_first = styled.div`
  background-image: url(${img_our});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 767px) {
  }
`;

const OurSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media all and (max-width: 767px) {
    grid-template-columns: 100vw;
    grid-template-rows: auto 1fr;
  }
`;

const OurInfo = styled.div`
  .title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    span {
      color: red;
    }
  }
  p:nth-child(2) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

function IntroduceDetail() {
  const { t } = useTranslation();
  useEffect(() => {
    fadeAnimation();
  });
  return (
    <div style={{ textAlign: "center" }}>
      <H4 className="fade">GLOBAL LOGISTICS SQUARE</H4>
      <H3 className="fade">{t("고객과 함께 성장")}</H3>
      <DetailImg>
        <Map className="fade">
          <img src={img_map} alt="" />
        </Map>
        <Blank ht="400px"></Blank>

        <Wrap>
          <Content className="fade">
            <H1>
              <img src={logo_white} alt="GLS" />
              <br></br>
              THE NEXT LEADER OF <span>GLOBAL</span> LOGISTICS INDUSTRY
            </H1>
            <Download>{t("회사 소개서 다운로드")}</Download>
          </Content>
          <Content className="fade">
            <Blank ht="200px"></Blank>
            <H2>{t("저희 GLS는")}</H2>
            <Info>{t("저희 GLS는_desc")}</Info>
          </Content>
        </Wrap>

        <Blank ht="100px"></Blank>
      </DetailImg>

      <OurSection>
        <Our_first>
          <img src={logo_white} alt="GLS" />
        </Our_first>
        <Our bg="rgb(54,53,69)">
          <BiMap size={50} />
          <Blank ht="100px"></Blank>
          <OurInfo className="fade">
            <p className="title">
              <span>OUR</span> ADDRESS
            </p>
            <p style={{ fontWeight: "bold" }}>{t("본사 위치")}</p>
            <p>{t("본사 주소")}</p>
          </OurInfo>
        </Our>
        <Our bg="rgb(36,35,50)">
          <BiPhone size={50} />
          <Blank ht="100px"></Blank>
          <OurInfo className="fade">
            <p className="title">
              <span>OUR</span> CONTACT
            </p>
            <p style={{ fontWeight: "bold" }}>{t("본사 연락처")}</p>
            <p>
              Tel: +82) 02 – 3775 – 1180<br></br>Fax: +82) 02 – 3775 – 1181
            </p>
          </OurInfo>
        </Our>
        <Our bg="rgb(54,53,69)">
          <BiTime size={50} />
          <Blank ht="100px"></Blank>
          <OurInfo className="fade">
            <p className="title">
              <span>OUR</span> HOURS
            </p>
            <p>Monday-Friday: 9:00 – 18:00</p>
          </OurInfo>
        </Our>
      </OurSection>
    </div>
  );
}
export default IntroduceDetail;
