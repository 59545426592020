import styled from "styled-components";
//img,icons
import glsLogo from "../../assets/img/logo/glsconsol_h_white.png";

const FooterJPContainer = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .family {
      color: white;
      .family-item {
        box-sizing: border-box;
        padding: 30px 0;
        h1 {
          font-size: var(--font-base);
          font-weight: bolder;
          padding-bottom: 4px;
        }
        p {
          line-height: 1.6rem;
        }
      }
    }
    .footer-bottom {
      width: 100%;
      .logo {
        width: 180px;
        img {
          width: 100%;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .content {
      .family {
        flex-direction: column;
        .family-item {
          width: 100%;
          padding: 20px 0;
        }
      }
    }
  }
`;

function FooterJP() {
  const family = [
    {
      id: "hongkong",
      name: "TOKYO, JAPAN",
      address:
        "HULIC&NEW SHINBASHI BUILDING BUREX FIVE 1003, 11-10, SHINBASHI 2-CHOME, MINATO-KU, TOKYO, 105-0004, JAPAN",
      tel: "03-6868-5570",
      fax: "03-6685-2917",
    },
  ];

  return (
    <FooterJPContainer>
      <div className="content">
        <div className="family">
          {family.map((v, i) => {
            return (
              <div key={v.id} className="family-item">
                <h1>{v.name}</h1>
                <p>{v.address}</p>
                <p>Tel : {v.tel}</p>
                {v.fax ? <p>Fax : {v.fax}</p> : ""}
              </div>
            );
          })}
        </div>
        <div className="footer-bottom">
          <div className="logo">
            <img src={glsLogo} alt="" />
          </div>
          {/* <p>© Copyright 2019 GLS Co.,Ltd. All Rights Reserved.</p> */}
        </div>
      </div>
    </FooterJPContainer>
  );
}

export default FooterJP;
