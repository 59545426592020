import React from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  height: "400px",
};

const center = {
  lat: 37.4450724,
  lng: 126.6280573,
};

const locations = [
  { lat: 37.4450724, lng: 126.6280573 }, // Location 1
  { lat: 37.12345, lng: 126.98765 }, // Location 2
  { lat: 22.364394604037937, lng: 114.13547106680579 },
  { lat: 10.798300699792637, lng: 106.66857501071424 },
  { lat: 16.208375753078325, lng: 108.04543238500433 },
  { lat: 21.02954592291829, lng: 105.81026038210958 },
  { lat: 22.542200734743087, lng: 114.12226525331978 },
  { lat: 31.269437891934018, lng: 121.4988769959737 },
  { lat: 39.08060393725906, lng: 117.2551572000602 },
  { lat: 39.08060393725906, lng: 117.2551572000602 },
  { lat: 35.667594858322424, lng: 139.75532985674138 },

  // Add more locations here...
];

function FamilyMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCEOmgidvM9WTfaSQ1sTQPl19Lcd3fXi8w",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={3} onLoad={onLoad} onUnmount={onUnmount}>
      {locations.map((location, index) => (
        <MarkerF key={index} position={{ lat: location.lat, lng: location.lng }} icon={{ url: require("../../assets/img/marker.png"), scale: 30 }} />
      ))}
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default FamilyMap;
