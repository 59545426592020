import { useTranslation } from "react-i18next";

//components
import IntroduceDetail from "../components/info/introduceDetail";
import IntroduceMap from "../components/info/introduceMap";
import TopContainer from "../components/TopContainer";

//img
import bgImg from "../assets/img/info/introduce_main.jpg";

function Info() {
  const { t } = useTranslation();
  return (
    <div>
      <TopContainer bgImg={bgImg} wrap={`WE'RE GLS`} desc={t("info_top_desc")}></TopContainer>
      <IntroduceDetail></IntroduceDetail>
      <IntroduceMap></IntroduceMap>
    </div>
  );
}

export default Info;
